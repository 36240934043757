<template>
    <div class="wrap">
        <el-page-header @back="$router.back()" content="中奖记录" class="margin-bottom margin-top-xs"></el-page-header>
        <div class="flex justify-start" style="padding: 20px">
            <el-form :inline="true" :model="searchForm">
                <el-form-item label="活动名称">
                    <el-input v-model="searchForm.activity_name"></el-input>
                </el-form-item>
                <el-form-item label="中奖人">
                    <el-input v-model="searchForm.buyer_name"></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="searchForm.buyer_mobile"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="search()">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button plain @click="reset()">重置</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="success" @click="showCheck()">核销</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="tableData" v-loading="loading">
            <el-table-column type="index" width="50" />
            <el-table-column label="中奖人" prop="buyer_name" />
            <el-table-column label="联系电话" prop="buyer_mobile" />
            <el-table-column label="中奖时间" align="center">
                <template slot-scope="scope">
                    {{ $util.date.format(scope.row.created_at, 'yyyy-MM-dd hh:mm:ss') }}
                </template>
            </el-table-column>
            <el-table-column label="活动名称" prop="activity_name" />
            <el-table-column label="订单号" prop="order_no" />
            <el-table-column label="中奖商品" prop="goods_name" />
            <el-table-column label="商品海报">
                <template slot-scope="scope">
                    <el-image style="width: 50px; height: 50px" :src="scope.row.goods_image" :preview-src-list="[scope.row.goods_image]" />
                </template>
            </el-table-column>
            <el-table-column label="核销状态" align="center">
                <template slot-scope="scope">
                    <el-button type="success" size="mini" plain v-if="scope.row.check_status === 2">已核销</el-button>
                    <el-button type="danger" size="mini" plain v-else>未核销</el-button>
                </template>
            </el-table-column>
            <el-table-column label="核销码" prop="check_code" />
        </el-table>
        <div class="text-center margin-top">
            <el-pagination
                @current-change="pageChange"
                :current-page="searchForm.page"
                :page-sizes="pageSizes"
                :page-size="searchForm.page_size"
                layout="prev, pager, next, total, jumper"
                :total="searchForm.total"
            ></el-pagination>
        </div>
        <el-dialog title="请输入核销码" :visible.sync="dialogVisible" append-to-body width="900px" @close="beforeClose">
            <el-input v-model="checkCode" placeholder="请输入核销码" id="checkCode"></el-input>
            <div class="subItem" v-if="checkInfo.id">
                <div class="itemize">核销信息</div>
                <div class="row">
                    <div class="col">
                        <div class="col-label">订单号：</div>
                        <div class="col-value">{{ checkInfo.order_no }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="col-label">活动名称：</div>
                        <div class="col-value">{{ checkInfo.activity_name }}</div>
                        <div class="col-label">商品名称：</div>
                        <div class="col-value">{{ checkInfo.goods_name }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="col-label">买家：</div>
                        <div class="col-value">{{ checkInfo.buyer_name }}</div>
                        <div class="col-label">电话：</div>
                        <div class="col-value">{{ checkInfo.buyer_mobile }}</div>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeCheck()">取 消</el-button>
                <el-button type="primary" @click="check()" v-if="!this.checkInfo.id">验 证</el-button>
                <el-button type="primary" @click="confirm()" v-else>确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            searchForm: {
                activity_name: '',
                buyer_name: '',
                buyer_mobile: '',
                page: 1,
                page_size: 1,
                total: 0,
            },
            pageSizes: [15],
            loading: false,
            tableData: [],
            visibleGoods: false,
            dialogVisible: false,
            currentData: { goodsList: [] },
            checkCode: '',
            checkInfo: {},
        };
    },
    created: function() {
        this.search();
    },
    methods: {
        pageChange: function(page) {
            this.searchForm.page = page;
            this.search();
        },
        search: function() {
            this.loading = true;
            let searchForm = this.$util.emptyToUndefined(this.searchForm);
            this.$api.platformActivity.getAwardRecord(searchForm).then((res) => {
                this.searchForm.total = res.data.total;
                this.tableData = res.data.data;
                this.pageSizes = res.data.per_page.toString().split();
                this.searchForm.page_size = res.data.per_page;
                this.loading = false;
            });
        },
        reset: function() {
            this.searchForm.activity_name = '';
            this.searchForm.buyer_name = '';
            this.searchForm.buyer_mobile = '';
            this.search();
        },
        showCheck: function() {
            this.dialogVisible = true;
            this.$nextTick(() => {
                document.getElementById('checkCode').focus();
            });
        },
        beforeClose: function() {
            this.checkCode = '';
            this.checkInfo = {};
        },
        closeCheck: function() {
            this.dialogVisible = false;
        },
        check: function() {
            this.$api.platformActivity.checkAwardConfirm({ check_code: this.checkCode }).then((res) => {
                if (res.code === 200) {
                    this.checkInfo = res.data;
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                    });
                }
            });
        },
        confirm: function() {
            if (!this.checkInfo.id) {
                this.$message({
                    type: 'error',
                    message: '验证失败',
                });
                return;
            }
            this.$api.platformActivity.checkAward({ check_code: this.checkCode }).then((res) => {
                if (res.code === 200) {
                    this.$message({
                        type: 'success',
                        message: '核销成功',
                    });
                    this.closeCheck();
                    this.search();
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                    });
                }
            });
        },
    },
};
</script>
<style scoped lang="scss">
.subItem {
    margin-top: 20px;
    padding-top: 40px;
    position: relative;

    .itemize {
        font-size: 16px;
        color: #333;
        padding-left: 20px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .itemize:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 10px;
        height: 10px;
        background-color: #409eff;
    }
}
.row {
    margin-bottom: 22px;
    display: flex;
    font-size: 15px;
    font-weight: 400;
    color: #333;
    line-height: 28px;

    .col {
        display: inline-flex;

        .col-label {
            width: 120px;
            text-align: right;
        }

        .col-value {
            margin-left: 8px;
            margin-right: 20px;
            width: 260px;
        }
    }
}
</style>
